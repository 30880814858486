import React from "react"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import LayoutTitle from "../components/Layouts/LayoutTitle/LayoutTitle"
import { Accordion, Card, Button } from "react-bootstrap"
import LayoutForPageImage from "../components/Layouts/LayoutForPageImage/LayoutForPageImage"
import ListStudents from "../components/Students/List_students"
import ImageInstagram from "../components/Students/ImageInstagram"


const IndexPage = (props) => {
  const { t } = useTranslation()

  return (
    <div style={{ minHeight: '95vh'}} >
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <LayoutTitle><h1 >{t('page-students.h1')}</h1></LayoutTitle>

      
      <LayoutForPageImage>
        {/* <h4><br/> {t('page-students.h1-2')}</h4> */}
        <Accordion defaultActiveKey="0" style={{ margin: '0 auto', textAlign: 'center'}}>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="success" style={{ display: 'block', margin: '0 auto', padding: "1rem", textAlign: 'center', letterSpacing: '1px'}} eventKey="1" >
              {t('page-students.btnjoin')}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              {/* <Card.Body>Hello! I'm another body</Card.Body> */}
              <Card.Body>
              <a href="https://www.instagram.com/rubiks.cube.kids" target="blank"><p><span>&#x2714;</span>{t('page-students.textjoin1')}</p></a>
                <p><span>&#x2714;</span>{t('page-students.textjoin2')}</p>
                <p><span>&#x2714;</span>{t('page-students.textjoin3')}</p>
                <p><span>&#x2714;</span>{t('page-students.textjoin4')}</p>
                <a href="https://www.instagram.com/rubiks.cube.kids" target="blank">
                  <ImageInstagram />
                </a>
                
              </Card.Body>

            </Accordion.Collapse>
          </Card>
        </Accordion>
        <a href="https://www.instagram.com/rubiks.cube.kids" target="blank">
          <ListStudents />
        </a>
        
        {/* <a href="https://www.instagram.com/rubiks.cube.kids" target="blank">
          <Button variant="success">{t('page-students.btnmore')}</Button>
        </a> */}
        
      </LayoutForPageImage>
      
    
      {/* <span style={{ fontSize: '1rem', margin: "0 auto", display: 'block', padding: '1rem 1rem 0 1rem', textAlign: 'center' }}> {t('page-form.thx')}</span>
      <span role="img" aria-label="viva" style={{ fontSize: '7rem', margin: "0 auto", display: 'block', textAlign: 'center' }}>💋</span> */}
    </div>
  )
}

export default IndexPage
