import React from 'react';
// import { CardGroup, Card, Image } from "react-bootstrap"

// import Link from '../Link';
import styles from './list_students.module.css';
import { useTranslation } from "react-i18next"
import {Container, Row, Col,  } from 'react-bootstrap';
import Student1 from "../Students/Student_1"
import Student2 from "../Students/Student_2"
import Student3 from "../Students/Student_3"
import Student4 from "../Students/Student_4"
import Student5 from "../Students/Student_5"
import Student6 from "../Students/Student_6"
import Student7 from "../Students/Student_7"

const ListStudents = () => {
  const { t } = useTranslation();
    return <div className={styles.list_students}>
      <Container>
      {/* className="justify-content-md-center"  */}
        <Row className={styles.list_students_row}>
          <Col className={styles.list_students_col} xs={12} md={6} lg={3}>
            <Student1 />
            <div className={styles.text_box}>
              <p>{t('page-students.student1')}</p>
            </div>
          </Col>

          <Col className={styles.list_students_col} xs={12} md={6} lg={3}>
            <Student4 />
            <div className={styles.text_box}>
              <p>{t('page-students.student4')}</p>
            </div>
          </Col>

          <Col className={styles.list_students_col} xs={12} md={6} lg={3}>
            <Student6 />
            <div className={styles.text_box}>
              <p>{t('page-students.student6')}</p>
            </div>
          </Col>

          <Col className={styles.list_students_col} xs={12} md={6} lg={3}>
            <Student5 />
            <div className={styles.text_box}>
              <p>{t('page-students.student5')}</p>
            </div>
          </Col>

          <Col className={styles.list_students_col} xs={12} md={6} lg={3}>
            <Student7 />
            <div className={styles.text_box}>
              <p>{t('page-students.student7')}</p>
            </div>
          </Col>

          <Col className={styles.list_students_col} xs={12} md={6} lg={3}>
            <Student2 />
            <div className={styles.text_box}>
              <p>{t('page-students.student2')}</p>
            </div>
          </Col>

          <Col className={styles.list_students_col} xs={12} md={6} lg={3}>
            <Student3 />
            <div className={styles.text_box}>
              <p>{t('page-students.student3')}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
}
  
export default ListStudents;